import "./App.css";
import animation from "./assets/Website Building of Shopping Sale (1).gif";
import logo from "./assets/logo.png";
import tringledo from "./assets/Tringledo.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="w-48" alt="logo" />
        <img
          src={animation}
          className="App-logo"
          alt="under maintainance animation"
        />
        <p className=" text-gray-800 font-poppins text-2xl py-2">
          Website under construction
        </p>
        <p className="text-gray-800 text-sm font-poppins px-4">
          We are excited to announce that this website is getting upgraded!
        </p>
        <a href="https://www.tringledo.com/" target="_blank">
          <div className="text-center gap-4 mt-12">
            <img
              src={tringledo}
              className="w-10 text-center mx-auto"
              alt="tringledo logo"
            />
            <p className="text-gray-800 text-[10px] font-poppins px-4">
              Designing and developing by Tringledo
            </p>
          </div>
        </a>
      </header>
    </div>
  );
}

export default App;
